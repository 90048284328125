import styled from '@emotion/styled'

export const Container = styled.div`
  display: inline-flex;
  position: absolute;
  width: 500px;
  height: 465px;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 75px;
  margin-left: 15px;

  @keyframes spin-ray-bigwheel {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;


export const RayCirclecOntainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 460px;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
  margin-left: 13px;
  border-radius: 5px;

  .rays {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    margin: auto;
    margin-top: 60px;
    animation: spin-ray-bigwheel 8s linear infinite;
  }

  .red-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 150px;
    margin: auto;
    margin-top: 170px;
  }
`

export const RedBanner = styled.div`
  width: 0px;
  height: 150px;
  animation: reveal-right 1s linear forwards;
  overflow: hidden;
  z-index: 5;
  padding-top: 30px;
  margin-top: 40px;

  div.img-text {
    height: 100%;
    width: 110%;
    position: relative;

    .text {
      position: absolute;
      font-size: 55px;
      font-family: "Carnevalee Freakshow" !important;
      z-index: 2;
      top: -7px;
      left: 55px;

      .three {
        text-shadow: 0px -3px 0 #ffea00;
        color: #ffea00;
        font-size: 60px;
        position: absolute;
        width: 100px;
        height: 150px;
        top: 0;
        left: 0;
        margin-left: -73px;
        margin-top: -17px;
      }

      .gremlin {
        color: #02f3f5;
        margin-left: 22px;
      }

      .bonus {
        text-shadow: 0px 10px 6.9px rgba(39, 0, 0, 0.41);
        color: #fff;
        text-shadow: 0px 10px 6.9px rgba(39, 0, 0, 0.41);
        margin-left: 15px;
      }
    }

    img.bonus-banner {
      height: 80px;
      width: 480px;
      margin-left: 20px;
    }
  }

  @keyframes reveal-right {
    0% {
      width: 0px;
    }

    100% {
      width: 500px;
    }
  }
`

export const PrizeContainer = styled.div`
  font-family: 'MyriadPro' !important;
  font-weight: bold;
  text-shadow: 0px 10px 6.9px rgba(39, 0, 0, 0.41);
  font-size: 35px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 160px;
  margin-bottom: 185px;
  width: 143px;
  height: 70px;
  text-align: center;
  line-height: 70px;
`

export const TripleGremlin = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150px;
  height: 150px;
`